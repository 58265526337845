/**
 * Portfolio component
 *
 * Highlights some of  your creations. These can be designs, websites,
 * open source contributions, articles you've written and more.
 *
 * This is a great area for you to to continually add to and refine
 * as you continue to learn and create.
 */

import React from "react";

/**
 * Desk image
 *
 * Below is a sample desk image. Feel free to update this to an image of your choice,
 * updating below imageAltText to string that represents what you see in that image.
 *
 * Need an image? Check out https://unsplash.com to download a photo you
 * freely use on your site.
 */
import image from "../images/design-desk.jpeg";

const imageAltText = "desktop with books and laptop";

/**
 * Project list
 *
 * An array of objects that will be used to display for your project
 * links section. Below is a sample, update to reflect links you'd like to highlight.
 */
const projectList = [
  {
    title: "ShopEz",
    description:
      "ShopSpot ShopEZ is a simple, intuitive Android shopping app built with Jetpack Compose. It demonstrates modern Android development practices and provides a smooth shopping experience for users.",
    url: "https://github.com/OkelloSam21/ShopEZ",
  },
  {
    title: "Dogdom",
    description:
      "This is a mobile app which I developed while learning how compoae works and understanding fundermeental concepts for making fine user interfaces",
    url: "https://github.com/OkelloSam21/Dogdom",
  },
  {
    title: "Kazihub",
    description:
      "KaziHub is a mobile application designed to bridge the gap between job posters and workers looking for casual jobs. Whether you need a quick gig done or you're seeking short-term employment, KaziHub provides an easy-to-use platform to connect and collaborate.",
    url: "https://github.com/OkelloSam21/KaziHub",
  },
  {
    title: "Bulls Eye Android Game",
    description:
      "Crated a simple game using Android Studio and Kotlin. The game is about guessing a random number.",
    url: "https://github.com/OkelloSam21/Bullseyes-Android-Game.git",
  },
];

const Portfolio = () => {
  return (
    <section className="light" id="portfolio">
      <h2>Portfolio</h2>
      <div style={{ display: "flex", flexDirection: "row", paddingTop: "3rem" }}>
        <div style={{ maxWidth: "40%", alignSelf: "center" }}>
          <img
            src={image}
            style={{
              height: "90%",
              width: "100%",
              objectFit: "cover",
              animation: "1s ease-out 0s 1 slideIn",
            }}
            alt={imageAltText}
          />
        </div>
        <div className="container">
          {projectList.map((project) => (
            <div className="box" key={project.title}>
              <a href={project.url} target="_blank" rel="noopener noreferrer">
                <h3 style={{ flexBasis: "40px" }}>{project.title}</h3>
              </a>
              <p className="small">{project.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
